import Highcharts from 'highcharts/highcharts.js'
import annotations from 'highcharts/modules/annotations.js'
import highchartsMore from 'highcharts/highcharts-more.js'

annotations(Highcharts)
highchartsMore(Highcharts)

var ctx = null
var lastDataPoint = null

export function create(id, data) {
    if (ctx) return
    console.log('systemVolategeChart-> creating')

    const diagnostics = data.data.filter(e => {
        if ('BatteryVoltage' in e.Diagnostics) {
            return true
        }
        return false
    })

    // Check if we got some new data so we need to redraw the chart
    const lastDataPoint_ = diagnostics[diagnostics.length - 1]
    if (lastDataPoint != null) {
        if (lastDataPoint.timestamp.valueOf() == lastDataPoint_.timestamp.valueOf()) {
            return
        }
    }
    lastDataPoint = lastDataPoint_

    const batteryVoltage = diagnostics.map(e => {
        return [e.timestamp.getTime(), e.Diagnostics.BatteryVoltage]
    })
    const solarPanelVoltage = diagnostics.map(e => {
        return [e.timestamp.getTime(), e.Diagnostics.SolarPanelVoltage]
    })

    const firstTimestamp = diagnostics[0].timestamp.getTime()
    const lastTimestamp = diagnostics[diagnostics.length - 1].timestamp.getTime()

    var powerGood = false
    //var chargingEnabled = false
    var fastCharging = false
    var chargingCompleted = false
    //var preCharging = false
    var labels = []
    diagnostics.forEach(e => {
        if (!powerGood && e.Diagnostics.StatusFlags & 1) {
            powerGood = true
            labels.push({
                point: {
                    x: e.timestamp.getTime(),
                    y: e.Diagnostics.SolarPanelVoltage,
                    xAxis: 0,
                    yAxis: 0
                },
                text: 'Solar Power Good'
            })
        } else if (powerGood && !(e.Diagnostics.StatusFlags & 1)) {
            powerGood = false
            labels.push({
                point: {
                    x: e.timestamp.getTime(),
                    y: e.Diagnostics.SolarPanelVoltage,
                    xAxis: 0,
                    yAxis: 0
                },
                text: 'Solar Not Power Good'
            })
        }

        if (!fastCharging && e.Diagnostics.StatusFlags & 0x02) {
            console.log('Fast Charging Started', e.Diagnostics.StatusFlags.toString(16), e.timestamp)
            fastCharging = true
            labels.push({
                point: {
                    x: e.timestamp.getTime(),
                    y: e.Diagnostics.BatteryVoltage,
                    xAxis: 0,
                    yAxis: 0
                },
                text: 'Fast Charging Started'
            })
        } else if (fastCharging && !(e.Diagnostics.StatusFlags & 0x02)) {
            console.log('Fast Charging Ended', e.Diagnostics.StatusFlags.toString(16), e.timestamp)
            fastCharging = false
            labels.push({
                point: {
                    x: e.timestamp.getTime(),
                    y: e.Diagnostics.BatteryVoltage,
                    xAxis: 0,
                    yAxis: 0
                },
                text: 'Fast Charging Ended',
                verticalAlign: 'bottom'
            })
        }

       /* if (!preCharging && e.Diagnostics.StatusFlags & 0x04) {
            console.log('Pre-Charging Started', e.Diagnostics.StatusFlags.toString(16), e.timestamp)
            fastCharging = true
            labels.push({
                point: {
                    x: e.timestamp.getTime(),
                    y: e.Diagnostics.BatteryVoltage,
                    xAxis: 0,
                    yAxis: 0
                },
                text: 'Pre-Charging Started'
            })
        } else if (preCharging && !(e.Diagnostics.StatusFlags & 0x04)) {
            console.log('Pre-Charging Ended', e.Diagnostics.StatusFlags.toString(16), e.timestamp)
            preCharging = false
            labels.push({
                point: {
                    x: e.timestamp.getTime(),
                    y: e.Diagnostics.BatteryVoltage,
                    xAxis: 0,
                    yAxis: 0
                },
                text: 'Pre-Charging Ended'
            })
        }*/

     /*   if (!chargingEnabled && e.Diagnostics.StatusFlags & 0x10) {
            console.log('Charging Enabled', e.Diagnostics.StatusFlags.toString(16), e.timestamp)
            chargingEnabled = true
            labels.push({
                point: {
                    x: e.timestamp.getTime(),
                    y: e.Diagnostics.BatteryVoltage,
                    xAxis: 0,
                    yAxis: 0
                },
                text: 'Charging Enabled'
            })
        } else if (chargingEnabled && !(e.Diagnostics.StatusFlags & 0x10)) {
            chargingEnabled = false
            console.log('Charging Disabled', e.Diagnostics.StatusFlags.toString(16), e.timestamp)
            labels.push({
                point: {
                    x: e.timestamp.getTime(),
                    y: e.Diagnostics.BatteryVoltage,
                    xAxis: 0,
                    yAxis: 0
                },
                text: 'Charging Disabled'
            })
        }*/

        if (!chargingCompleted && e.Diagnostics.StatusFlags & 0x08) {
            console.log('Charging completed', e.Diagnostics.StatusFlags.toString(16), e.timestamp)
            chargingCompleted = true
            labels.push({
                point: {
                    x: e.timestamp.getTime(),
                    y: e.Diagnostics.BatteryVoltage,
                    xAxis: 0,
                    yAxis: 0
                },
                text: 'Charging completed'
            })
        }
    })

    ctx = Highcharts.chart(id, {
        title: null,

        xAxis: {
            type: 'datetime',
            labels: {
                format: '{value:%H:%M}'
            }
        },

        yAxis: {
            title: {
                text: 'Voltage (V)',
                margin: 5
            },
            labels: {
                formatter: function() {
                    //var value = this.value / 1000
                    return this.value / 1000
                }
            },
            min: 0,
            max: 7000
        },

        legend: {
            layout: 'vertical',
            align: 'center',
            verticalAlign: 'bottom',
            x: 40,
            enabled: true
        },

        tooltip: {
            crosshairs: true,
            shared: true,
            // valueSuffix: 'kn',
            // xDateFormat: '%H:%M',
            formatter: function() {
                var tip = '<b>' + new Date(this.x).toLocaleTimeString() + '</b>' + '<br>'
                tip += 'Battery Voltage: ' + this.points[0].y + ' mV' + '<br>'
                tip += 'Solar Panel Voltage: ' + this.points[1].y + ' mV'
                return tip
            }
        },

        series: [
            {
                name: 'BatteryVoltage',
                data: batteryVoltage,
                lineWidth: 2,
                lineColor: '#bfdacd',
                color: '#bfdacd',
                marker: {
                    enabled: false
                }
            },
            {
                name: 'SolarPanelVoltage',
                data: solarPanelVoltage,
                lineWidth: 2,
                lineColor: '#3a6284',
                color: '#3a6284',
                marker: {
                    enabled: false
                }
            },
            {
                name: 'Not charging area',
                type: 'arearange',
                showInLegend: false,
                data: [
                    [firstTimestamp, 4100, 3800],
                    [lastTimestamp, 4100, 3800]
                ],
                lineWidth: 0,
                color: '#ebf7e8',
                fillOpacity: 0.8,
                zIndex: -1,
                marker: {
                    enabled: false
                }
            }
        ],
        time: {
            useUTC: false
        },
        annotations: [
            {
                labels: labels,
                labelOptions: {
                    allowOverlap: true
                }
            }
        ]
    })
    return ctx
}

export function destroy() {
    console.log('systemVolategeChart -> destroying')
    if (ctx) ctx.destroy()
    ctx = null
    lastDataPoint = null
}
