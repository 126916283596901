import Highcharts from 'highcharts/highcharts.js'
import highchartsMore from 'highcharts/highcharts-more.js'
import windbarb from 'highcharts/modules/windbarb.js'
import { SMA } from 'downsample'

highchartsMore(Highcharts)
windbarb(Highcharts)

var ctx
var localData
var currentMaxTick = -1

export function create(id, data) {
    if (ctx) return
    console.log('windForceChart-> creating')
    localData = Array.from(data)

    const d_ = smoothData(data)
    const gustsLulls = d_.map((e) => {
        return [e[0], e[1], e[2]]
    })
    const maxMins = d_.map((e) => {
        return [e[0], e[3], e[4]]
    })
    const median = d_.map((e) => {
        return [e[0], e[5]]
    })

    currentMaxTick = -1

    const hideRange = (localData.length > 120) ? true : false

    ctx = Highcharts.chart(id, {
        title: null,

        xAxis: {
            type: 'datetime',
            /*   accessibility: {
                rangeDescription: 'Range: Jul 1st 2009 to Jul 31st 2009.'
            }*/
            labels: {
                format: '{value:%H:%M}',
            },
            tickInterval: 10 * 60 * 1000
        },

        yAxis: {
            title: {
                text: 'Wind (kn)',
                margin: 0,
            },
            min: 0,
            max: 12,
        },

        tooltip: {
            crosshairs: true,
            shared: true,
            // valueSuffix: 'kn',
            // xDateFormat: '%H:%M',
            formatter: function() {
                var tip = '<b>' + new Date(this.x).toLocaleTimeString() + '</b>' + '<br>'
                tip += 'Gusts: ' + Math.round(this.points[0].point.low * 10) / 10 + '<br>'
                tip += 'Lulls: ' + Math.round(this.points[0].point.high * 10) / 10
                return tip
            },
        },

        series: [
            {
                name: 'Median',
                showInLegend: false,
                data: median,
                type: 'spline',
                lineWidth: 2,
                lineColor: '#94bdb8',
                color: '#94bdb8',
                //fillOpacity: 0.9,
                zIndex: 0,
                marker: {
                    enabled: false,
                },
                visible: hideRange
            },
            {
                name: 'GustsLulls',
                showInLegend: false,
                data: gustsLulls,
                type: 'arearange',
                lineWidth: 2,
                lineColor: '#94bdb8',
                color: '#94bdb8',
                linkedTo: 'previous',
                zIndex: 0,
                marker: {
                    enabled: false,
                },
                visible: !hideRange
            },
            {
                name: 'MaxMins',
                showInLegend: false,
                data: maxMins,
                type: 'arearange',
                lineWidth: 2,
                lineColor: '#bfdacd',
                color: '#bfdacd',
                linkedTo: 'previous',
                fillOpacity: 0.6,
                zIndex: -1,
                marker: {
                    enabled: false,
                },
                visible: !hideRange
            },
        ],
        time: {
            useUTC: false,
        },
    })
    setRange()
    return ctx
}

export function destroy() {
    console.log('windForceChart -> destroying')
    if (ctx) ctx.destroy()
    ctx = null
}

export function addData(t) {
    console.log('windForceChart -> adding new datapoint')
    if (!ctx) return

    localData.push(t)
    const data = smoothData(localData)
    const point = data[data.length - 1]

    setRange()

    ctx.series[0].addPoint([point[0], point[1], point[2]], false, true, true)
    ctx.series[0].addPoint([point[0], point[2], point[3]], false, true, true)
    ctx.redraw()
}

function smoothData(data) {
    const gusts_ = data.map((e) => {
        return [e.timestamp.getTime(), e.WindForceGust]
    })
    const lulls_ = data.map((e) => {
        return [e.timestamp.getTime(), e.WindForceLull]
    })
    const maxs_ = data.map((e) => {
        return [e.timestamp.getTime(), e.WindForceMax]
    })
    const mins_ = data.map((e) => {
        return [e.timestamp.getTime(), e.WindForceMin]
    })
    const median_ = data.map((e) => {
        return [e.timestamp.getTime(), e.WindForceMedian]
    })

    const gusts = SMA(gusts_, 3, 1)
    const lulls = SMA(lulls_, 3, 1)
    const maxs = SMA(maxs_, 3, 1)
    const mins = SMA(mins_, 3, 1)
    const median = SMA(median_, 3, 1)
    
    const data_ = gusts.map((e, i) => {
        return [e.x, e.y, lulls[i].y, maxs[i].y, mins[i].y, median[i].y]
    })
    return data_
}

function setRange() {
    console.log("updating max tick")
    const maxTick = localData.reduce((min, current) => Math.max(min, current.WindForceMax), localData[0].WindForceMax)    
    if (maxTick == currentMaxTick) return

    currentMaxTick = maxTick

    var max = 12
    if (maxTick > 48) max = 60
    else if (maxTick > 36) max = 48
    else if (maxTick > 24) max = 36
    else if (maxTick > 12) max = 24

    console.log("max rtick", maxTick, max);

    ctx.yAxis[0].update({
        max: max,
        min: 0,
    })    
}
