<template>
    <v-container d-flex>
        <v-img class="mx-auto">
            <div id="chart-system" class="chart-container"></div>
        </v-img>
    </v-container>
</template>

<script>
import * as systemVoltageChart from '../charts/systemVoltageChart'

export default {
    name: 'System',
    props: { windData: Object },
    data() {
        return {
            mounted: false,
            dataPointRef: null
        }
    },
    watch: {
        windData: {
            deep: true,
            immediate: true,
            handler: function(newData) {
                console.log('System-> in watch')
                if (newData.data.length == 0) return

                const diagnostics = newData.data.filter(e => {
                    if ('BatteryVoltage' in e.Diagnostics) {
                        return true
                    }
                    return false
                })
                const lastData = diagnostics[diagnostics.length - 1]

                if (this.dataPointRef) {
                    if (this.dataPointRef.timestamp.valueOf() == lastData.timestamp.valueOf()) {
                        // no new data
                        return
                    }
                }
                this.dataPointRef = lastData
                if (this.mounted) {
                    this.createChart()
                }
            }
        }
    },
    methods: {
        createChart() {
            systemVoltageChart.destroy()
            systemVoltageChart.create('chart-system', this.windData)
        }
    },
    mounted: function() {
        console.log('System-> mounted hook')
        this.mounted = true
        this.createChart()
        systemVoltageChart.create('chart-system', this.windData)
    },
    beforeDestroy: function() {
        systemVoltageChart.destroy()
    }
}
</script>

<style></style>
