<template>
    <v-container>
        <v-data-table
            :headers="headers"
            :items="tableData"
            item-key="name"
            class="elevation-1"
            :disable-sort="true"
            :dense="true"
            mobile-breakpoint="320"
            :footer-props="{
                disableItemsPerPage: true,
                itemsPerPageOptions: []
            }"
        >
            <template v-slot:item.WindForceMedian="{ item }">
                <v-chip :color="getColor(item.WindForceMedian)" dark x-small text-color="black">
                    {{ item.WindForceMedian }}
                </v-chip>
            </template>
            <template v-slot:item.WindForceGust="{ item }">
                <v-chip :color="getColor(item.WindForceGust)" dark x-small text-color="black">
                    {{ item.WindForceGust }}
                </v-chip>
            </template>
            <template v-slot:item.WindForceLull="{ item }">
                <v-chip :color="getColor(item.WindForceLull)" dark x-small text-color="black">
                    {{ item.WindForceLull }}
                </v-chip>
            </template>
            <template v-slot:item.WindDirectionAvg="{ item }">
                <span >{{item.WindDirectionAvg}}° ({{degToCompass(item.WindDirectionAvg)}})</span>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import Moment from 'moment'

export default {
    name: 'System',
    props: { windData: Object },
    data() {
        return {
            headers: [
                {
                    text: 'Time',
                    align: 'start',
                    sortable: false,
                    value: 'timestamp'
                    // width: '75px'
                },
                { text: 'Force', value: 'WindForceMedian' },
                { text: 'Gust', value: 'WindForceGust' },
                { text: 'Lull', value: 'WindForceLull' },
                { text: 'Direction', value: 'WindDirectionAvg' }
            ],
            tableData: []
        }
    },
    watch: {
        windData: {
            deep: true,
            immediate: true,
            handler: function(newData) {
                console.log('WindTable-> in watch')

                if (newData.data.length == 0) return

                this.tableData = newData.data.slice(-60)
                this.tableData = this.tableData.map(element => {
                    return {
                        timestamp: Moment(element.timestamp).format('HH:mm'),
                        WindForceMedian: element.WindForceMedian,
                        WindForceGust: element.WindForceGust,
                        WindForceLull: element.WindForceLull,
                        WindForceAvg: element.WindForceAvg,
                        WindForceMax: element.WindForceMax,
                        WindForceMin: element.WindForceMin,
                        WindDirectionAvg: element.WindDirectionAvg
                    }
                })
                this.tableData.reverse()
            }
        }
    },
    methods: {
        getColor(force) {
            if (force < 4) return '#bfdacd'
            else if (force < 8) return '#94bdb8'
            else if (force < 16) return '#6c9fa7'
            else if (force < 32) return '#4c8197'
            else return '#3a6284'
        },
        degToCompass(num) {
            var val = Math.floor(num / 22.5 + 0.5)
            var arr = [
                'N',
                'NNE',
                'NE',
                'ENE',
                'E',
                'ESE',
                'SE',
                'SSE',
                'S',
                'SSW',
                'SW',
                'WSW',
                'W',
                'WNW',
                'NW',
                'NNW'
            ]
            return arr[val % 16]
        }
    }
}
</script>

<style>
.v-data-table th {
    font-size: 0.6em !important;
    /* height: 24px !important; */
    padding: 0 7px !important;
}

.v-data-table td {
    font-size: 0.6em !important;
    /* height: 24px !important; */
    padding: 0 7px !important;
}
</style>
