import { firestoreDB } from "./config"

export function getStationsMetadata() {
    return firestoreDB.collection("stations").get();
}

export function unsubscribeStation(token) {
    if (token) token()
}

export function getCollectionReference(station, date) {
    console.log("day", date.getDate())
    return firestoreDB.collection("year").doc(`${date.getUTCFullYear()}`).collection("month").doc(`${date.getUTCMonth()}`).collection("day").doc(`${date.getUTCDate()}`)
        .collection(station)
        .orderBy("timestamp", "asc")
}