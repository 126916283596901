<template>
    <v-app>
        <v-app-bar fluid app dense color="#ebf7e8" height="36" class="py-3">
            <v-img
                src="./assets/color2_logo_transparent_background.png"
                max-width="128"
                class="mx-auto"
            ></v-img>

            <template v-slot:extension>
                <v-tabs centered @change="tabSelected" color="#3a6284">
                    <v-tabs-slider color="#3a6284"></v-tabs-slider>
                    <v-tab class="caption py-2" v-for="station in stations" :key="station.name">
                        {{ station.name }}
                    </v-tab>
                </v-tabs>
            </template>
        </v-app-bar>

        <v-main>
            <v-overlay :value="isLoadingData" :absolute="true" :opacity="0.6">
                <v-progress-circular :size="150" color="rgb(204, 221, 238)" indeterminate></v-progress-circular>
            </v-overlay>

            <v-container fluid>
                <router-view :windData="this.windData"></router-view>
            </v-container>
        </v-main>

        <v-footer app fluid dense color="#ebf7e8">
            <v-progress-linear
                class="my-0"
                color="#6c9fa7"
                buffer-value="0"
                stream
                v-model="progressBar.updateTimeout"
            ></v-progress-linear>
            <v-bottom-navigation v-model="viewChange" value background-color="#ebf7e8" class="elevation-0 my-0">
                <v-btn
                    v-for="view in views"
                    :key="view.message"
                    icon
                    color="#3a6284"
                    :value="view.path"
                    :disbaled="isLoadingData"
                    
                    min-width="55"
                >
                    <v-icon dense>{{ view.icon }}</v-icon>
                </v-btn>
            </v-bottom-navigation>
        </v-footer>
    </v-app>
</template>

<script>
import slugify from 'slugify'
import * as firestore from './firebase/firestore'
import { signIn } from './firebase/config'

export default {
    name: 'App',

    components: {
        //  About,
    },

    data() {
        return {
            view: null,
            views: [
                { icon: 'fa-wind', path: 'WindCurrent' },
                { icon: 'fa-chart-area', path: 'WindForce' },
                { icon: 'fa-chart-pie', path: 'WindRose' },
                { icon: 'fa-table', path: 'WindTable' },
                { icon: 'fa-thermometer-half', path: 'Temperatures' },
                { icon: 'fa-charging-station', path: 'System' }
            ],
            stations: [],
            station: null,
            date: new Date(),
            windData: { station: this.station, data: [] },
            progressBar: {
                updateTimeout: 0,
                updateTimeoutCount: 0,
                updateTimer: null
            },
            snapshotUnsuscribe: null
        }
    },
    watch: {},
    computed: {
        viewChange: {
            get() {
                return this.view
            },
            set(e) {
                if (e == null) return
                console.log(e)
                this.$router.push({ name: e }).catch(() => {})
            }
        },
        isLoadingData() {
            return this.windData.data.length == 0
        }
    },
    mounted: function() {
        signIn()
            .then(() => {
                console.log('App-> signed in anonimously')
                firestore.getStationsMetadata().then(t => {
                    t.docs.forEach(doc => {
                        const data = doc.data()
                        this.stations.push({
                            name: data.name,
                            position: data.position,
                            offset: data.DirectionOffset,
                            slug: slugify(data.name, { lower: true })
                        })
                    })
                    console.log('station metadata:', this.stations)
                    this.fetchData(0, new Date())
                })
            })
            .catch(error => {
                // Handle Errors here.
                var errorCode = error.code
                var errorMessage = error.message
                console.log(error)
                console.log(errorCode, errorMessage)

                if (errorCode === 'auth/operation-not-allowed') {
                    alert('You must enable Anonymous auth in the Firebase Console.')
                } else {
                    console.error(error)
                }
            })
    },

    methods: {
        tabSelected(e) {
            console.log('App -> tab selected', e, this.station)
            if (this.station != null && this.station != e) {
                this.fetchData(e, this.date)
            }
            this.station = e
        },
        fetchData(station, date) {
            if (this.snapshotUnsuscribe) {
                console.log('Unsuscribing from previous collection')
                this.snapshotUnsuscribe()
                this.windData.data.length = 0
            }

            const stationSlug = this.stations[station].slug
            this.snapshotUnsuscribe = firestore.getCollectionReference(stationSlug, date).onSnapshot(snap => {
                console.log('New snapshot length:', snap.docChanges().length, new Date().toString())
                const windData_ = snap.docChanges().map(change => {
                    if (change.type === 'added') {
                        const e = change.doc.data()
                        var diagnostics = {}
                        if ("BatteryVoltage" in e) {
                            diagnostics = { BatteryVoltage: e.BatteryVoltage,
                                            SolarPanelVoltage: e.SolarPanelVoltage,
                                            StatusFlags: e.StatusFlags,
                                            AirTemperature: e.AirTemperature,
                                            WaterTemperature: e.WaterTemperature}
                        }
                        return {
                            timestamp: e.timestamp.toDate(),
                            WindForceAvg: e.WindForceAvg,
                            WindForceMedian: e.WindForceMedian,
                            WindForceGust: e.WindForceGust,
                            WindForceLull: e.WindForceLull,
                            WindForceMax: e.WindForceMax,
                            WindForceMin: e.WindForceMin,
                            WindDirectionAvg: e.WindDirectionAvg,
                            WindDirectionMax: e.WindDirectionMax,
                            WindDirectionMin: e.WindDirectionMin,
                            Diagnostics: diagnostics
                        }
                    }
                })
                windData_.forEach(e => {
                    this.windData.data.push(e)
                })
                this.windData.station = this.station

                if (this.progressBar.updateTimer) clearInterval(this.progressBar.updateTimer)
                this.progressBar.updateTimeoutCount = 0
                this.progressBar.updateTimer = setInterval(() => {
                    this.progressBar.updateTimeoutCount += (1 / 600) * 100
                    this.progressBar.updateTimeout = Math.round(this.progressBar.updateTimeoutCount)
                }, 100)
            })
        }
    }
}
</script>
