<template>
    <div>
        <v-switch dense inset v-model="fullDay" label="All day data" @change="createChart" class="subtitle-1"></v-switch>
        <v-img class="mx-auto">
            <div id="chart-rose" class="chart-container"></div>
        </v-img>
    </div>
</template>

<script>
import * as roseChart from '../charts/windRoseChart'

export default {
    neme: 'RoseChart',
    props: { windData: Object },
    data() {
        return {
            chartCtx: null,
            timeRange: null,
            dataPointRef: null,
            mounted: false,
            fullDay: false,
        }
    },
    watch: {
        windData: {
            deep: true,
            immediate: true,
            handler: function(newData) {
                console.log('WindRose-> in watch')
                // In case dataset was cleared due to day/station change return (and wait for valid data)
                if (newData.data.length == 0) return
                // If the datapoint reference has been set already we need to check if
                // we got a new datapoint or an entire new dataset
                if (this.dataPointRef) {
                    if (
                        this.dataPointRef.timestamp.valueOf() != newData.data[0].timestamp.valueOf() ||
                        this.dataPointRef.WindForceMedian != newData.data[0].WindForceMedian
                    ) {
                        // new data set
                        console.log('WindRose-> new dataset', this.dataPointRef, newData.data[0])
                    } else {
                        // new data point
                        console.log('WindRose-> new datapoint')
                        roseChart.update(this.getRangeData(this.windData.data))
                        return
                    }
                }
                this.dataPointRef = newData.data[0]
                if (this.mounted) {
                    this.createChart()
                }
                
            }
        }
    },
    computed: {
    },
    methods: {
        createChart() {
            roseChart.destroy()
            roseChart.create('chart-rose', this.getRangeData(this.windData.data))
        },
        getRangeData(data) {
            console.log('WindRose -> fullday', this.fullDay)
            const lastTimestamp = new Date(data[data.length - 1].timestamp)
            if (this.fullDay) {
                return data
            } else {
                // get last 3 hours worth of data
                var t = lastTimestamp.getHours() - 2
                if(t < 0) t = 0
                const t_ = new Date(lastTimestamp).setHours(t)
                var oldestTimestamp = new Date(t_)
                return data.filter(e => e.timestamp > oldestTimestamp)
            }
        }
    },
    mounted: function() {
        console.log('WindRose -> mounted hook', this.windData.data)
        this.mounted = true
        roseChart.create('chart-rose', this.getRangeData(this.windData.data))
    },
    beforeDestroy: function() {
        roseChart.destroy()

    }
}
</script>

<style>
</style>
