<template>
    <div>
        <v-switch
            dense
            inset
            v-model="fullDay"
            label="All day data"
            @change="createChart"
            class="subtitle-1"
        ></v-switch>
        <v-img class="mx-auto">
            <div id="chart-force" class="chart-container" :style="orientation"></div>
        </v-img>
    </div>
</template>

<script>
/* <v-checkbox
            class="pt-3"
            v-model="fullDay"
            :label="historyLabel"
            @change="createChart"
            color="#6c9fa7"
            off-icon="fa-clock"
            on-icon="fa-history"
        ></v-checkbox> */
import * as forceChart from '../charts/windForceChart'

export default {
    neme: 'WindChart',
    props: { windData: Object },
    data() {
        return {
            chartCtx: null,
            timeRange: null,
            dataPointRef: null,
            mounted: false,
            fullDay: false,
            orientationDeg: 0,
            currentMaxTick: 0
        }
    },
    watch: {
        windData: {
            deep: true,
            immediate: true,
            handler: function(newData) {
                console.log('WindForce-> in watch')
                // In case dataset was cleared due to day/station change return (and wait for valid data)
                if (newData.data.length == 0) return
                // If the datapoint reference has been set already we need to check if
                // we got a new datapoint or an entire new dataset
                if (this.dataPointRef) {
                    if (
                        this.dataPointRef.timestamp.valueOf() != newData.data[0].timestamp.valueOf() ||
                        this.dataPointRef.WindForceMedian != newData.data[0].WindForceMedian
                    ) {
                        // new data set
                        console.log('WindForce-> new dataset', this.dataPointRef, newData.data[0])
                    } else {
                        // new data point
                        const t = newData.data[newData.data.length - 1]
                        console.log('WindForce-> new datapoint', t)
                        forceChart.addData(t)
                        return
                    }
                }
                this.dataPointRef = newData.data[0]
                if (this.mounted) {
                    this.createChart()
                }
            }
        }
    },
    computed: {},
    methods: {
        orientationChange(e) {
            console.log('the orientation of the device is now ' + e.target.screen.orientation.angle)
            this.orientationDeg = e.target.screen.orientation.angle
        },
        orientation() {
            return { transform: 'rotate(' + this.orientationDeg + 'turn)' };
        },
        getRangeData(data) {
            console.log('WindForce-> fullday', this.fullDay)
            const lastTimestamp = new Date(data[data.length - 1].timestamp)
            if (this.fullDay) {
                return data
            } else {
                var t = lastTimestamp.getHours() - 2
                if (t < 0) t = 0
                const t_ = new Date(lastTimestamp).setHours(t)
                var oldestTimestamp = new Date(t_)
                return data.filter(e => e.timestamp > oldestTimestamp)
            }
        },
        maxTickUpdate(t) {
            const max = t.reduce((min, current) => Math.max(min, current.WindForceGust), t[0].WindForceGust)
            if (max != this.currentMaxTick) return max
            return null
        },
        createChart() {
            forceChart.destroy()
            const data = this.getRangeData(this.windData.data)
            forceChart.create('chart-force', data)
        }
    },
    mounted: function() {
        console.log('WindForce-> mounted hook')
        this.mounted = true

        this.dataPointRef = this.windData.data[0]
        this.createChart()

        window.addEventListener('orientationchange', this.orientationChange)
    },
    beforeDestroy() {
        window.removeEventListener('orientationchange', this.orientationChange)
    }
}
</script>

<style>
#container {
    transform: rotate(90deg);
}
</style>
