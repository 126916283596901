<template>
    <v-img d-flex max-width="240" class="mx-auto">
        <div id="chart-current-force" class="chart-container"></div>

        <div id="chart-current-direction" class="chart-container"></div>
    </v-img>
</template>

<script>
import * as gaugeForce from '../charts/windForceGauge'
import * as gaugeDirection from '../charts/windDirectionGauge'

export default {
    neme: 'WindCurrent',
    props: { windData: Object },
    data() {
        return {
            mounted: false,
            chartForceCtx: null,
            chartDirectionCtx: null,
            currentData: null,
            timer: null
        }
    },
    watch: {
        windData: {
            deep: true,
            immediate: true,
            handler: function(newData) {
                console.log('WindCurrent -> in watch')
                // In case dataset was cleared due to day/station change return (and wait for valid data)
                if (newData.data.length == 0) return

                if (!this.mounted) {
                    this.currentData = newData.data[newData.data.length - 1]
                    return
                }
                this.currentData = newData.data[newData.data.length - 1]

                if (!this.chartForceCtx || !this.chartDirectionCtx) {
                    this.createChart(this.currentData)
                    return
                }
                this.updateChart()
            }
        }
    },
    computed: {},
    methods: {
        createChart(data) {
            this.chartForceCtx = gaugeForce.create('chart-current-force', data)
            this.chartDirectionCtx = gaugeDirection.create('chart-current-direction', data)
            

            if (this.timer == null) {
                this.timer = setInterval(() => {
                    // Calculate wind force jitter data
                    const max = this.currentData.WindForceGust
                    const min = this.currentData.WindForceLull
                    const num = Math.random() * (max - min) + min
                    var t = Math.round(num * 10) / 10
                    if (t < min) t = min
                    gaugeForce.updateCurrent(t)

                    // Calculate wind direction jitter data
                    var diff = this.currentData.WindDirectionMax - this.currentData.WindDirectionMin
                    var min_ = this.currentData.WindDirectionMin
                    if (this.currentData.WindDirectionMax < this.currentData.WindDirectionMin) {
                        diff = this.currentData.WindDirectionMin - this.currentData.WindDirectionMax
                        min_ = this.currentData.WindDirectionMax
                    }

                    t = Math.round(Math.random() * diff + min_)
                    t %= 360

                    console.log(
                        'WindCurrent->',
                        ' Avg:',
                        this.currentData.WindDirectionAvg,
                        ' Min:',
                        this.currentData.WindDirectionMin,
                        ' Max:',
                        this.currentData.WindDirectionMax,
                        ' NewAvg:',
                        t
                    )
                    gaugeDirection.updateCurrent(t)
                }, 5000)
            }
        },
        updateChart() {
            console.log('WindCurrent-> Updating chart')            

            gaugeForce.update(
                this.currentData.WindForceMedian,
                this.currentData.WindForceLull,
                this.currentData.WindForceGust
            )
            gaugeDirection.update(
                this.currentData.WindDirectionAvg,
                this.currentData.WindDirectionMin,
                this.currentData.WindDirectionMax
            )
        }
    },
    mounted() {
        this.mounted = true
        if (this.currentData) {
            if (!this.chartForceCtx || !this.chartDirectionCtx) {
                this.createChart(this.currentData)
            }
        }
    },
    beforeDestroy() {
        if (this.timer) clearInterval(this.timer)
        gaugeForce.destroy()
        gaugeDirection.destroy()
    }
}
</script>

<style></style>
