import Vue from 'vue'
import VueRouter from 'vue-router'
import WindCurrent from '../views/WindCurrent.vue'
import WindForce from '../views/WindForce.vue'
import WindRose from '../views/WindRose.vue'
import Temperatures from "../views/Temperatures.vue"
import System from "../views/System.vue"
import WindTable from "../views/WindTable.vue"

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'WindCurrent',
        component: WindCurrent,
        props: true
    },
    {
        path: '/force',
        name: 'WindForce',
        component: WindForce,
        props: true
    },
    {
        path: '/rose',
        name: 'WindRose',
        component: WindRose,
        props: true
    },
    {
        path: '/temperatures',
        name: 'Temperatures',
        component: Temperatures,
        props: true
    },
    {
        path: '/system',
        name: 'System',
        component: System,
        props: true
    },
    {
        path: '/table',
        name: 'WindTable',
        component: WindTable,
        props: true
    }
]

const router = new VueRouter({
    routes,
    mode: 'history'
})

router.afterEach(() => {
   // Vue.$emit("after each")

})

export default router
