var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tableData,"item-key":"name","disable-sort":true,"dense":true,"mobile-breakpoint":"320","footer-props":{
            disableItemsPerPage: true,
            itemsPerPageOptions: []
        }},scopedSlots:_vm._u([{key:"item.WindForceMedian",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.WindForceMedian),"dark":"","x-small":"","text-color":"black"}},[_vm._v(" "+_vm._s(item.WindForceMedian)+" ")])]}},{key:"item.WindForceGust",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.WindForceGust),"dark":"","x-small":"","text-color":"black"}},[_vm._v(" "+_vm._s(item.WindForceGust)+" ")])]}},{key:"item.WindForceLull",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.WindForceLull),"dark":"","x-small":"","text-color":"black"}},[_vm._v(" "+_vm._s(item.WindForceLull)+" ")])]}},{key:"item.WindDirectionAvg",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.WindDirectionAvg)+"° ("+_vm._s(_vm.degToCompass(item.WindDirectionAvg))+")")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }