import Highcharts from 'highcharts/highcharts.js'
import highchartsMore from 'highcharts/highcharts-more.js'
import solidGauge from 'highcharts/modules/solid-gauge.js'

highchartsMore(Highcharts)
solidGauge(Highcharts)

var ctx = null
var zeroCrossing = null

var gaugeOptions = {
    chart: {
        type: 'gauge',
        height: '100%',
        spacingBottom: 0,
        spacingTop: 0,
        margin: [0, 0, 0, 0]
    },
    plotOptions: {
        gauge: {
            pivot: {
                radius: 0
            },
            dial: {
                baseLength: '60%',
                baseWidth: 30,
                radius: '100%',
                rearLength: '-60%',
                topWidth: 1,
                backgroundColor: '#bfdacd'
            },
            dataLabels: {
                borderWidth: 0,
                y: -10
            }
        }
    },

    title: null,
    pane: {
        startAngle: 0,
        endAngle: 360,
        background: {
            backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc'
        }
    },

    exporting: {
        enabled: false
    },

    tooltip: {
        enabled: false
    },

    // the value axis
    yAxis: [
        {
            min: 0,
            max: 360,
            tickAmount: 16,
            labels: {
                formatter: function() {
                    var val = Math.floor(this.value / 22.5 + 0.5)
                    var arr = [
                        'N',
                        'NNE',
                        'NE',
                        'ENE',
                        'E',
                        'ESE',
                        'SE',
                        'SSE',
                        'S',
                        'SSW',
                        'SW',
                        'WSW',
                        'W',
                        'WNW',
                        'NW',
                        'NNW'
                    ]
                    return arr[val % 16]
                }
            }
        }
    ]
}

export function create(id, data) {
    ctx = Highcharts.chart(
        id,
        Highcharts.merge(gaugeOptions, {
            yAxis: {
                min: 0,
                max: 360,
                title: {
                    text: 'Direction'
                }
            },
            credits: {
                enabled: false
            },

            series: [
                {
                    name: 'Direction',
                    data: [data.WindDirectionAvg],
                    dataLabels: {
                        format:
                            '<div style="text-align:center">' +
                            '<span style="font-size:18px">{y}°</span><br/>' +
                            '</div>'
                    }
                },
                {
                    type: 'gauge',
                    data: [data.WindDirectionMax],
                    dial: {
                        baseLength: '110%',
                        radius: '101%',
                        rearLength: '-110%',
                        baseWidth: 15,
                        backgroundColor: '#6c9fa7'
                    },
                },
                {
                    type: 'gauge',
                    data: [data.WindDirectionMin],
                    dial: {
                        baseLength: '110%',
                        radius: '101%',
                        rearLength: '-110%',
                        baseWidth: 15,
                        backgroundColor: '#6c9fa7'
                    },
                },
            ],
        })
    )
    return ctx
}

export function update(current, min, max) {
    if (ctx == null) return;

    ctx.series[0].points[0].update(current);
    if (max < min) {
        ctx.series[1].points[0].update(min);
        ctx.series[2].points[0].update(max);
        zeroCrossing = true;
    } else {
        ctx.series[1].points[0].update(max);
        ctx.series[2].points[0].update(min);
        zeroCrossing = false;
    }
}

export function updateCurrent(current) {
    ctx.series[0].points[0].update(current, true, !zeroCrossing);
}

export function destroy() {
    if (ctx) ctx.destroy()
    ctx = null
    zeroCrossing = null
}
