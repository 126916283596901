import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'



// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCwzvCvb6BHAeolKwn-acSwhgnxmfpVFB8",
    authDomain: "windstation.firebaseapp.com",
    projectId: "windstation",
    storageBucket: "windstation.appspot.com",
    messagingSenderId: "1027577238254",
    appId: "1:1027577238254:web:ea2846a11a0d71cd6d59ff",
    measurementId: "G-PMM7RF0K18"
};

firebase.initializeApp(firebaseConfig)
firebase.firestore().enablePersistence()

console.log("Initializing firebase")
const firestoreDB = firebase.firestore()
/*
firebase.firestore().disableNetwork().then(() =>{
    console.log("firestore network access disabled")
})
*/

function signIn() {
    console.log("authorizing")
    return firebase.auth().signInAnonymously()
}


export {firestoreDB, signIn}
