import Highcharts from 'highcharts/highcharts.js'
import highchartsMore from 'highcharts/highcharts-more.js'

highchartsMore(Highcharts)

var ctx = null
var lastDataPoint = null

export function create(id, data) {
    if (ctx) {
        console.log('ctx is not null', ctx, id)
        return
    }
    console.log('temperaturesChart-> creating')

    const diagnostics = data.data.filter(e => {
        if ('AirTemperature' in e.Diagnostics) {
            return true
        }
        return false
    })

    // Check if we got some new data so we need to redraw the chart
    const lastDataPoint_ = diagnostics[diagnostics.length - 1]
    if (lastDataPoint != null) {
        if (lastDataPoint.timestamp.valueOf() == lastDataPoint_.timestamp.valueOf()) {
            return
        }
    }
    lastDataPoint = lastDataPoint_

    const airTemperature = diagnostics.map(e => {
        return [e.timestamp.getTime(), e.Diagnostics.AirTemperature]
    })
    const waterTemperature = diagnostics.map(e => {
        return [e.timestamp.getTime(), e.Diagnostics.WaterTemperature]
    })

    ctx = Highcharts.chart(id, {
        title: null,

        xAxis: {
            type: 'datetime',
            labels: {
                format: '{value:%H:%M}'
            }
        },

        yAxis: {
            title: {
                text: 'Temperature (°C)',
                margin: 5
            },
            min: 0,
            max: 40
        },

        legend: {
            layout: 'vertical',
            align: 'top',
            verticalAlign: 'top',
            x: 40,
            enabled: true
        },

        tooltip: {
            crosshairs: true,
            shared: true,
            // valueSuffix: 'kn',
            // xDateFormat: '%H:%M',
            formatter: function() {
                var tip = '<b>' + new Date(this.x).toLocaleTimeString() + '</b>' + '<br>'
                tip += 'Air temperature: ' + this.points[0].y + ' °C' + '<br>'
                tip += 'Water temperature:: ' + this.points[1].y + ' °C'
                return tip
            }
        },

        series: [
            {
                name: 'AirTemperature',
                data: airTemperature,
                lineWidth: 2,
                lineColor: '#bfdacd',
                color: '#bfdacd',
              //  zIndex: 0,
                marker: {
                    enabled: false
                }
            },
            {
                name: 'WaterTemperature',
                data: waterTemperature,
                lineWidth: 2,
                lineColor: '#3a6284',
           //     linkedTo: 'previous',
                color: '#3a6284',
                marker: {
                    enabled: false
                }
            }
        ],
        time: {
            useUTC: false
        }
    })
    return ctx
}

export function destroy() {
    console.log('temperaturesChart -> destroying')
    if (ctx) ctx.destroy()
    ctx = null
    lastDataPoint = null
}
