import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
//import { construct } from "core-js/fn/reflect";

highchartsMore(Highcharts);

var ctx

export function create(id, data) {

    const series0 = { data: buildHistogram(data, 0, 4), type: "column", name: "0-4kn" }
    const series1 = { data: buildHistogram(data, 4, 8), type: "column", name: "4-8kn" }
    const series2 = { data: buildHistogram(data, 8, 16), type: "column", name: "8-16kn" }
    const series3 = { data: buildHistogram(data, 16, 32), type: "column", name: "16-32kn" }
    const series4 = { data: buildHistogram(data, 32, 70), type: "column", name: "32+kn" }
    
    ctx = Highcharts.chart(id, {

        chart: {
            polar: true,
            type: 'column'
        },

        title: null,

        pane: {
            center: ["50%", "50%"],
            size: "85%",
        },

        legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
        },

        xAxis: {
            tickmarkPlacement: 'on',
            categories: ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW']
        },

        yAxis: {
            min: 0,
            endOnTick: false,
            showLastLabel: true,
            reversedStacks: false
        },

        tooltip: {
            valueSuffix: "%",
        },

        series: [series0, series1, series2, series3, series4],

        plotOptions: {
            series: {
                stacking: 'normal',
                shadow: false,
                groupPadding: 0,
                pointPlacement: 'on'
            }
        },
        colors: ['#bfdacd', '#94bdb8', '#6c9fa7', '#4c8197', '#3a6284']
    });
    return ctx
}

function degToCompass(num) {
    var val = Math.floor(num / 22.5 + 0.5);
    var arr = [
        "N",
        "NNE",
        "NE",
        "ENE",
        "E",
        "ESE",
        "SE",
        "SSE",
        "S",
        "SSW",
        "SW",
        "WSW",
        "W",
        "WNW",
        "NW",
        "NNW",
    ];
    return arr[val % 16];
}

function buildHistogram(data, min, max) {
    var series = []

    const t = data.filter(e => (e.WindForceMedian >= min && e.WindForceMedian < max))
    const d = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW']
    d.forEach(d => {
        const len = t.filter(e => d == degToCompass(e.WindDirectionAvg)).length
        const ratio = Math.round((len / data.length) * 100)
        series.push([d, ratio])
    })
    return series
}

export function update(value) {
    if (ctx == null) return

    // 0 - 4
    // 4 - 8
    // 8 - 16
    // 16 - 32
    // 32+

    var series = { data: buildHistogram(value, 0, 4), type: "column", name: "0-4kn" }
    ctx.series[0].update(series)

    series = { data: buildHistogram(value, 4, 8), type: "column", name: "4-8kn" }
    ctx.series[1].update(series)

    series = { data: buildHistogram(value, 8, 16), type: "column", name: "8-16kn" }
    ctx.series[2].update(series)

    series = { data: buildHistogram(value, 16, 32), type: "column", name: "16-32kn" }
    ctx.series[3].update(series)

    series = { data: buildHistogram(value, 32, 70), type: "column", name: "32+kn" }
    ctx.series[4].update(series)
}

export function destroy() {
    console.log('windRoseChart -> destroying')
    if (ctx) ctx.destroy()
    ctx = null
}
