import Highcharts from 'highcharts/highcharts.js'
import highchartsMore from 'highcharts/highcharts-more.js'
import solidGauge from 'highcharts/modules/solid-gauge.js'

highchartsMore(Highcharts)
solidGauge(Highcharts)

var ctx = null
var localData
var currentMaxTick = -1

var gaugeOptions = {
    chart: {
        type: 'solidgauge',
        height: '100%',
        spacingBottom: 0,
        spacingTop: 0,
        margin: [0, 0, 0, 0],
    },

    title: null,
    pane: {
        //size: "90%",
        startAngle: -130,
        endAngle: 130,
        background: {
            backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc',
        },
    },

    exporting: {
        enabled: false,
    },

    tooltip: {
        enabled: true,
        formatter: function() {
            if (this.series.name == 'Max force') {
                return 'Gust: ' + this.y + 'kn'
            } else if (this.series.name == 'Min force') {
                return 'Lull: ' + this.y + 'kn'
            } else {
                return 'Current: ' + this.y + 'kn'
            }
        },
    },

    // the value axis
    yAxis: [
        {
            // 0-4
            // 4-8
            // 8-16
            // 16-32
            // 32-64
            // 64+
           // stops: [[0, '#7ac968']],
            lineWidth: 0,
            minorTickInterval: 'auto',
            tickAmount: 4,
            title: {
                y: -40,
            },
            labels: {
                distance: '65%',
                y: 25,
            },
        },
    ],
    plotOptions: {
        solidgauge: {
            dataLabels: {
                y: -10,
                borderWidth: 0,
                useHTML: true,
            }
        }
    },
}

export function create(id, data) {
    localData = data
    const current = data.WindForceMedian
    const gust = data.WindForceGust
    const lull = data.WindForceLull
    currentMaxTick = -1

    ctx = Highcharts.chart(
        id,
        Highcharts.merge(gaugeOptions, {
            yAxis: {
                min: 0,
                max: 12,
                title: {
                    text: 'Force',
                },
            },
            credits: {
                enabled: false,
            },

            series: [
                {
                    name: 'Force',
                    data: [{y: current, color: '#bfdacd'}],

                    dataLabels: {
                        format: '<div style="text-align:center">' + '<span style="font-size:18px">{y}kn</span><br/>' + '</div>',
                    },
                    tooltip: {
                        valueSuffix: 'kn',
                    },
                },
                {
                    name: 'Max force',
                    innerRadius: '115%',
                    radius: '102%',
                    data: [{y:gust, color:'#6c9fa7'}],
                },
                {
                    name: 'Min force',
                    innerRadius: '45%',
                    radius: '58%',
                    data: [{y:lull, color:'#94bdb8'}],
                    color: 'red'
                },
            ],
        })
    )
    setRange()
    return ctx
}

function setRange() {
    var max = 12
    if (localData.WindForceGust > 48) max = 60
    else if (localData.WindForceGust > 36) max = 48
    else if (localData.WindForceGust > 24) max = 36
    else if (localData.WindForceGust > 12) max = 24

    if(currentMaxTick == max) return
    currentMaxTick = max
    
    ctx.yAxis[0].update({
        max: max,
        min: 0,
    })
}

export function update(current, min, max) {
    if (ctx == null) return

    ctx.series[0].points[0].update(current, false)
    ctx.series[1].points[0].update(max, false)
    ctx.series[2].points[0].update(min, false)
    setRange()
    ctx.redraw()
}

export function updateCurrent(current) {
    if (ctx == null) return

    ctx.series[0].points[0].update(current, false)
    setRange()
    ctx.redraw()
}

export function destroy() {
    if (ctx) ctx.destroy()
    ctx = null
    currentMaxTick = -1
}
